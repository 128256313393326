import { FunctionComponent, useEffect, useRef } from "react"
import { Helmet as Head } from "react-helmet"
import ChannelView from "../components/channel/view"
import { useChannel } from "../hooks/use-channel"
import TagFilters from "../components/layout/tag-filters"
import React from "react"
import SortFilter from "../components/layout/sort-filter"
import Aliases from "../components/layout/aliases"
import Bookmarks from "../components/layout/bookmarks"
import Feeds from "../components/layout/feeds"
import Notice from "../components/widgets/notice"
import { useUser } from "../hooks/use-user"
import MinimalSortFilter from "../components/layout/minimal-sort-filter"
import FeedbackFilter from "../components/layout/feedback-filter"
import ThreeColumnLayout from "../components/layout/three-column-layout"

const ChannelPage: FunctionComponent = ({ location }) => {
  const parts = location.pathname.split("/")
  const initialChannelId = parts[2]
  const { channel, donationsEnabled } = useChannel(initialChannelId, "date")
  const { user } = useUser()

  return (
    <>
      {channel && false && (
        <Head>
          <title>{channel.name} | Smartlike</title>
          <meta property="og:title" content={`${channel.name} | Smartlike`} />
          {channel.description && (
            <>
              <meta name="description" content={channel.description} />
              <meta property="og:description" content={channel.description} />
            </>
          )}
          {channel.image && (
            <meta property="og:image" content={channel.image} />
          )}
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/channel/${channel.id}`}
          />
        </Head>
      )}
      <ThreeColumnLayout channel={channel}>
        {{
          content: (
            <>
              {user?.id == initialChannelId && (
                <Notice id="mycontent_notice" submit="Got it">
                  <p>
                    When you sign your content with your Smartlike account (
                    {user?.id}) or connect it to your accounts elsewhere,
                    donations will be automatically routed. You can monitor your
                    content here. <a href="#">Read more.</a>
                  </p>
                </Notice>
              )}

              <Feeds initialChannelIds={[initialChannelId]} kind="channel" />
            </>
          ),
          sidebar: (
            <>
              <SortFilter />
              <MinimalSortFilter />
              <FeedbackFilter />
              <ChannelView
                channel={channel}
                donationsEnabled={donationsEnabled}
              />

              {channel && channel.aliases && channel.aliases.length ? (
                <Aliases aliases={channel.aliases} />
              ) : (
                <></>
              )}
              <TagFilters />
              <Bookmarks />
            </>
          ),
        }}
      </ThreeColumnLayout>
    </>
  )
}
export default ChannelPage
