import { FunctionComponent, useMemo, createElement, useCallback } from "react"
import AnimateHeight from "react-animate-height"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faStream,
  faClock,
  faFilter,
  faChevronUp,
  faChevronDown,
  faSave,
  faTimes,
  faWindowClose,
} from "@fortawesome/pro-light-svg-icons"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import { useFilters } from "../../hooks/use-filters"
import Checkbox from "./form/checkbox"
import React from "react"
import { Tooltip } from "@material-ui/core"
import { Link } from "@reach/router"
import { Alias } from "../../types"
import Avatar from "./avatar"

type Props = {
  aliases: Alias[]
}

const Aliases: FunctionComponent<Props> = ({ aliases }) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  return (
    <div className="accordion">
      <section>
        <header>
          <h1>
            <span>aliases</span>
          </h1>
        </header>
      </section>
      <section>
        <div className="content">
          {aliases.map(a => (
            <div key={a.id}>
              <Link to={`/channel/${a.id}`} style={{ textDecoration: "none" }}>
                <div className="link">
                  <Avatar
                    image={a.image ?? undefined}
                    label={a.title}
                    size={20}
                  />

                  <div className="alias">{a.title}</div>
                  {a.cut > 0 && <div className="cut">{a.cut}%</div>}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>
      <style jsx>{`
        .cut {
          font-size: 12px;
          font-family: Roboto;
        }
        .link {
          min-width: 50px;
          display: flex;
          align-items: center;
          text-decoration: none;
          margin-top: 3px;
        }
        .link:hover strong {
          text-decoration: underline;
        }
        .alias {
          margin-left: 0.5em;
          font-size: 12px;
          font-family: Roboto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          display: block;
        }

        header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          font-family: Roboto;
        }
        header > :global(:not(:first-child)) {
          margin-left: 1em;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: inherit;
          font-style: inherit;
          font-weight: normal;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: lighter;
        }
        h1 > *:not(:first-child) {
          margin-left: 1em;
        }

        .accordion {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
          font-size: 0.9em;
          user-select: none;
        }
        section:not(:first-child) {
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .content {
          padding: 20px;
          /*border-top: 1px solid ${css.borderColor};*/
          flex-wrap: wrap;

          /*display: inline-flex;*/
        }
        .tag {
          background-color: rgb(105, 105, 105, 0.1);
          padding: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 14px;
          word-break: keep-all;
          user-select: none;
        }
        .tag:hover {
          background-color: rgb(105, 105, 105, 0.3);
          cursor: pointer;
        }
        .filterConrols {
          flex-wrap: wrap;
          display: inline-flex;
        }
        .filterAction {
          font-size: 20px;
        }
        .filterAction:hover {
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Aliases
